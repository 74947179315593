<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <div class="app-max-width">
        <ion-toolbar class="text-center">
        
          <ion-buttons slot="start">
            <ion-button @click.prevent="goBack()">
              <ion-icon :icon="chevronBack"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-title class="text-capitalize">Send Message</ion-title>
        
        </ion-toolbar>
      </div>
    </ion-header>
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div class="app-max-width">
        <SendMessageForm :username="username" />
      </div>
    </ion-content>
    <!-- Footer -->
    <ion-footer>
      <Tabs page="send-message" />
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon } from '@ionic/vue';
import { chevronBack, apps, reorderThree, options } from 'ionicons/icons';
import { defineComponent, onMounted, ref } from 'vue';
import doAuth from '../../services/auth';
import Tabs from '../../components/Tabs.vue';
import SendMessageForm from '../../components/SendMessageForm.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'SendMessageUser',
  components: {
    IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon, Tabs, SendMessageForm
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const username = ref(null);

    onMounted(() => {
      username.value = router.currentRoute._rawValue.params.username
    })

    function goBack() {
      router.go(-1)
    }

    function updateRoute(val) {
      router.push(val)
    }

    return {
      chevronBack, router, goBack, username, apps, reorderThree, options, updateRoute
    }
  },
});
</script>

<style scoped>

</style>